import { Icon } from 'components/common/Icon'
import { Spinner } from 'components/common/Spinner'
import Link from 'next/link'
import {
  ButtonHTMLAttributes,
  Children,
  ComponentProps,
  DetailedHTMLProps,
  HTMLAttributeAnchorTarget,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import { tw } from '../../utils/strings'

const StyleToSpinnerStyleMap: {
  [key: string]: ComponentProps<typeof Spinner>['theme']
} = {
  'solid-black': 'white',
  'solid-primary': 'white',
  'solid-success': 'white',
  'solid-white': 'purple',
  outline: 'white',
  text: 'white',
}

type Props = {
  children?: React.ReactNode
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void
  className?: string
  type?: 'button' | 'submit' | 'reset' | 'link'
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  style?:
    | 'solid-black'
    | 'solid-primary'
    | 'solid-success'
    | 'solid-white'
    | 'solid-danger'
    | 'outline'
    | 'text'
  disabled?: boolean
  pill?: boolean
  href?: string
  useAnchor?: boolean
  form?: string
  target?: HTMLAttributeAnchorTarget
  'aria-label'?: string
  download?: boolean
  isLoading?: boolean
  'data-e2e'?: string
} & Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'type' | 'style'
>

export const Button = ({
  children,
  onClick,
  className,
  type = 'button',
  size = 'md',
  style = 'solid-black',
  disabled,
  pill,
  href,
  form,
  target = '_self',
  download,
  isLoading,
  useAnchor,
  ...props
}: Props) => {
  const [onlyIcon, setOnlyIcon] = useState(false)

  useEffect(() => {
    if (isSingleChildOfType(children)) {
      setOnlyIcon(true)
    }
  }, [children])

  const isSingleChildOfType = (otherChildren: React.ReactNode) => {
    const childrenArray = Children.toArray(otherChildren)
    if (childrenArray.length !== 1) return false

    const child = childrenArray[0] as ReactElement
    if (child.type === Icon) {
      return true
    }

    return false
  }

  const isRounded = () => {
    if (pill) {
      return 'rounded-full'
    } else {
      if (size === 'xl') {
        return 'rounded-lg'
      } else {
        return 'rounded'
      }
    }
  }

  const styles = {
    base: tw`flex cursor-pointer items-center font-medium justify-center duration-200 ease-in-out hover:no-underline  [&_svg]:pointer-events-none `,

    'solid-black': tw`border-none bg-cool-800 text-cool-50 outline-none hover:bg-cool-900 focus:bg-cool-900`,
    'solid-primary': tw`border-none bg-primary-default text-cool-50 outline-none hover:bg-purple-500 focus:bg-purple-600`,
    'solid-white': tw`border border-solid border-cool-400 bg-cool-50 text-cool-700 hover:text-cool-800 hover:border-cool-500 hover:bg-cool-50 focus:border-cool-600 focus:bg-cool-50`,
    'solid-success': tw`border-none bg-success-default text-cool-50 outline-none hover:bg-success-dark focus:bg-success-dark`,
    'solid-danger': tw`border-none bg-chili-800 text-cool-50 outline-none hover:bg-chili-900`,
    outline: tw`border border-solid border-cool-400 bg-transparent text-cool-700 hover:text-cool-800 hover:border-cool-500 hover:bg-transparent focus:border-cool-600 focus:bg-transparent`,
    text: tw`!bg-transparent text-cool-800 hover:bg-cool-300 focus:bg-cool-400`,

    sm: tw`${onlyIcon ? 'w-8 text-xs' : 'px-3 text-sm'} h-8`,
    md: tw`${onlyIcon ? 'w-10 text-xs' : 'px-4 text-sm'} h-10`,
    lg: tw`${onlyIcon ? 'w-12' : 'px-5'} h-12 text-base`,
    xl: tw`${onlyIcon ? 'w-16' : 'px-6'} h-16 text-base`,
    '2xl': tw`${onlyIcon ? 'w-21' : 'px-7'} h-21 text-base`,

    rounded: isRounded(),

    disabled: tw`${
      disabled ? '!cursor-default !bg-cool-400 !text-cool-500' : ''
    }`,
  }

  const baseClassName = `${styles.base} ${styles[style]} ${styles[size]} ${styles.rounded} ${styles.disabled}`

  if (type === 'link') {
    return useAnchor ? (
      <a
        href={href || ''}
        className={`${baseClassName} ${className}`}
        onClick={e => {
          if (disabled) {
            e.preventDefault()
            return
          }

          onClick?.(e)
        }}
        target={target}
        rel={target === '_blank' ? 'noreferrer noopener' : undefined}
        download={download}
        data-e2e={props['data-e2e']}
      >
        {children}
      </a>
    ) : (
      <Link
        href={href || ''}
        prefetch={false}
        className={`${baseClassName} ${className}`}
        onClick={e => {
          if (disabled) {
            e.preventDefault()
            return
          }

          onClick?.(e)
        }}
        target={target}
        rel={target === '_blank' ? 'noreferrer noopener' : undefined}
        download={download}
        data-e2e={props['data-e2e']}
      >
        {children}
      </Link>
    )
  }

  return (
    <button
      disabled={disabled}
      form={form}
      type={type}
      onClick={onClick}
      className={`${baseClassName} ${className} relative`}
      data-e2e={props['data-e2e']}
      {...props}
    >
      {isLoading && (
        <div className={`${baseClassName} absolute left-0 top-0 z-10 w-full`}>
          <Spinner theme={StyleToSpinnerStyleMap[style]} />
        </div>
      )}

      {children}
    </button>
  )
}
